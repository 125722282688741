<template>
  <!-- 凭证接收 - 文件夹 -->
  <div class="voucherDetail" v-loading="pagination.loading">
    <div class="facilityBox">
      <!-- 面包屑 -->
      <el-breadcrumb separator="/" style="margin:0 0 10px 0;">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>凭证接收</el-breadcrumb-item>
        <el-breadcrumb-item>查看</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- tab切换 -->
      <el-tabs v-model="flag.activeTab" @tab-click="(pagination.page = 1), getVoucherFilesList()">
        <el-tab-pane label="未关联" name="1"></el-tab-pane>
        <el-tab-pane label="已关联" name="2"></el-tab-pane>
      </el-tabs>

      <!-- 合并文件夹 -->
      <el-button @click="mergeFiles" type="primary" icon="el-icon-wallet"
        v-show="flag.activeTab == 1 && pagination.list != 0">合并文件夹</el-button>

      <!-- 文件夹 -->
      <el-checkbox-group class="voucher-files" v-model="checkList">
        <div class="file-box" v-for="item in pagination.list" :key="item.FolderId">
          <div class="file-time" @click="toImageDetail(item, 'showImageDetail')">
            <p>{{ item.ModifyTime }}</p>
            <i @click.stop="deleteRelation(item.FolderId)" v-show="flag.activeTab == 2"
              class="edit-name el-icon-delete"></i>
          </div>
          <el-checkbox :checked="item.Check" class="checkBtn" :label="item.FolderName" :value="item.FolderId"
            v-if="flag.activeTab == 1" @change="chooseVoucherFiles($event, item.FolderId)"></el-checkbox>
          <p v-else class="checkBtn">{{ item.FolderName }}</p>
          <el-button type="primary" plain icon="el-icon-document-copy" size="small" v-if="flag.activeTab == 2" @click="
            (flag.relationOrder = true),
            (folderObj = item),
            (orderPagination.page = 1),
            getAllOrderList('look')
            ">查看</el-button>
          <el-button type="primary" plain icon="el-icon-document-copy" size="small" @click="
            (flag.relationOrder = true),
            (folderObj = item),
            (orderPagination.page = 1),
            getAllOrderList(flag.activeTab == 1 ? 'relation' : 'update')
            ">{{ flag.activeTab == 1 ? "关联" : "修改" }}</el-button>
          <el-button type="danger" plain icon="el-icon-folder-delete" size="small" v-if="flag.activeTab == 1"
            @click="backFolder(item.FolderId)">退回</el-button>
        </div>
      </el-checkbox-group>

      <!-- 分页 -->
      <el-pagination v-show="pagination.list != 0" class="pagination" background @current-change="handleCurrentChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>

      <!-- 暂无内容 -->
      <div class="noData" v-show="pagination.list == 0 && !pagination.loading"></div>

      <!-- 返回 -->
      <el-button class="goBack" type="info" plain v-show="!pagination.loading" icon="el-icon-arrow-left"
        @click="goBack">返回</el-button>
    </div>

    <!-- 文件夹中详细图片 -->
    <ImageDetail @showImageDetail="showImageDetail" @showImagePreview="showImagePreview" :userId="userId"
      :folderObj="folderObj" ref="imageDetail" class="ImageDetail" v-if="flag.showImageDetail" :fileType="flag.activeTab">
    </ImageDetail>

    <!-- 预览图片 -->
    <el-dialog :visible.sync="flag.previewImage" width="1200px" class="previewImage" :close-on-click-modal="false"
      :show-close="false">
      <el-image style="width: 100%;height:684px;" :src="previewImageObj.FileURL" fit="contain"></el-image>
      <span slot="footer" class="dialog-footer">
        <span class="previewImage-time">
          上传时间: {{ previewImageObj.ModifyTime }}
        </span>
        <el-button @click="flag.previewImage = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 关联运单 -->
    <el-dialog :visible.sync="flag.relationOrder" width="1200px" :title="`${orderType == 'look' ? '查看' : orderType == 'update' ? '修改' : '关联'
      }`
      " :close-on-click-modal="false" class="relationOrder">
      <!-- 选择关联订单 - 搜索条件 -->
      <el-form ref="orderForm" inline :model="orderForm" v-if="this.orderType != 'look'">
        <el-form-item label="开票申请编号：" label-width="120px">
          <el-input v-model.trim="orderForm.invoiceTaskId" placeholder="请输入开票申请编号" style="width: 200px;" clearable
            @clear="(orderPagination.page = 1), getAllOrderList()"></el-input>
        </el-form-item>

        <el-button type="primary" @click="(orderPagination.page = 1), getAllOrderList()" plain icon="el-icon-search"
          style="margin-left: 16px;">搜索</el-button>
        <el-button type="danger" @click="clearSearch" icon="el-icon-delete" plain
          style="margin-left: 16px;">清空</el-button>
      </el-form>
      <!-- 选择关联订单 - 表格 -->
      <el-table ref="multipleTable" :data="orderPagination.list" tooltip-effect="dark" style="width: 100%"
        v-loading="orderPagination.loading">
        <el-table-column label="选择" width="55" align="center" fixed="left" v-if="this.orderType != 'look'">
          <template slot-scope="scope">
            <el-radio :label="scope.row.InvoiceTaskID" v-model="multipleSelection"
              @change.native="handleSelectionChange(scope.row.InvoiceTaskID)">{{ "" }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="index" label="序号" width="50" align="center" v-if="this.orderType != 'look'">
        </el-table-column>
        <el-table-column prop="InvoiceTaskID" label="开票申请编号" width="170" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="WaybillCount" label="运单数" width="80" align="center">
        </el-table-column>
        <el-table-column prop="SupplierName" label="服务商名称" width="170" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="InvoiceTypeName" label="发票类型" width="160" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="TypeName" label="业务类型" width="100" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="ScenesTypeName" label="场景类型" width="80" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="PayModeString" label="支付方式" width="100" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="Price" label="运单总金额" width="120" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color: #FF8B17;">{{
              scope.row.Price | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="InvoiceApplyDatetime" label="开票申请时间" width="160" align="center" show-overflow-tooltip>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination class="pagination" background v-if="this.orderType != 'look'" style="margin-top: 16px;"
        @current-change="handleCurrentOrderChange" :current-page.sync="orderPagination.page"
        :page-size="orderPagination.pagesize" layout="total, prev, pager, next, jumper" :total="orderPagination.total">
      </el-pagination>

      <!-- 凭证图片 -->
      <div class="formItem-title" v-if="flag.showSmallImageDetail && orderType == 'look'">
        <span></span>
        <p>相关凭证</p>
      </div>
      <ImageDetail @showImageDetail="showImageDetail" @showImagePreview="showImagePreview" :userId="userId"
        :folderObj="folderObj" ref="imageDetail" v-if="flag.showSmallImageDetail && orderType == 'look'"
        class="smallImageDetail" :fileType="flag.activeTab" :hideElement="true"></ImageDetail>

      <!-- 选择关联订单 - 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag.relationOrder = false">取 消</el-button>
        <el-button v-if="orderType != 'look'" icon="el-icon-document-copy" @click="relationOrder"
          type="primary">关联</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ImageDetail from "./imageDetail";
import {
  GetOwnerFolderListAsync,
  PutMergeFoldersAsync,
  GetInvoiceTaskAsync,
  PostSetEmFolderBindAsync,
  DeleteCancelEmFolderBindAsync,
  UpdateEmFolderBindAsync,
  DeleteFolderReturnAsync,
} from "@/api/voucherManagement/voucherManagement.js";
export default {
  data() {
    return {
      userId: "", // 上一页传过来的 司机id
      flag: {
        activeTab: "1", // tab切换
        showImageDetail: false, // 打开文件夹， 查看图片
        previewImage: false, // 预览图片
        relationOrder: false, // 关联运单
        showSmallImageDetail: false,
      },
      checkList: [], // 多选选中文件夹集合
      checkIdList: [], // 多选选中文件夹id集合
      folderObj: "", // 选中的文件夹
      pagination: {
        // 文件夹分页
        loading: false,
        page: 1,
        pagesize: 10,
        total: 0,
        list: [],
      },
      orderForm: {
        invoiceTaskId: "", // 开票申请编号号
      },
      tableData: [
        {
          time: "2016-05-02",
          name: "王小虎",
          index: "1",
        },
        {
          time: "2016-05-02",
          name: "王小虎",
          index: "2",
        },
      ],
      multipleSelection: "", // 存储选的开票申请编号
      previewImageObj: {}, // 存储预览图片的相关数据
      orderPagination: {
        // 所有开票申请编号分页
        loading: false,
        page: 1,
        pagesize: 10,
        total: 0,
        list: [],
      },
      orderType: "", // 选中/修改/查看关联
    };
  },
  components: {
    ImageDetail,
  },
  created() {
    let { userId } = this.$route.query;
    if (userId) this.userId = userId;
    this.getVoucherFilesList();
  },
  methods: {
    // 获取凭证文件夹列表
    getVoucherFilesList() {
      this.pagination.loading = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        userId: this.userId,
        isBind: this.flag.activeTab == 1 ? false : true, // 是否关联
      };

      GetOwnerFolderListAsync(params)
        .then((res) => {
          let { TotalCount, DataList } = res.data;
          this.pagination.total = TotalCount;
          this.pagination.list = DataList;
        })
        .finally(() => {
          this.pagination.loading = false;
        });
    },

    // 文件夹分页
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getVoucherFilesList();
    },

    // 打开、关闭文件夹查看内部图片集合
    showImageDetail(type) {
      this.flag.showImageDetail = type;
    },

    // 打开子组件的图片， 预览
    showImagePreview(type, data) {
      this.previewImageObj = data;
      this.flag.previewImage = type;
    },

    // 合并文件夹
    mergeFiles() {
      if (this.checkIdList.length < 2) {
        this.$message({
          message: "请勾选需要合并的文件夹",
          type: "info",
        });
        return;
      }

      this.$prompt("文件夹名称", `合并文件夹`, {
        confirmButtonText: "合并",
        cancelButtonText: "取消",
        customClass: "mergePrompt",
        inputPlaceholder: "请输入新的文件夹名称",
        inputValidator(value) {
          if (!value) {
            return "请输入新的文件夹名称";
          } else if (value && value.length > 10) {
            return "文件夹名称限10个汉字";
          }
        },
      })
        .then((e) => {
          PutMergeFoldersAsync(e.value, this.checkIdList).then((res) => {
            this.$message({
              message: "文件夹合并成功",
              type: "success",
            });
            this.getVoucherFilesList();
          });
        })
        .catch(() => { });
    },

    // 删除关联
    deleteRelation(id) {
      this.$alert(
        "<strong><p style='text-align: left;'>确认要删除关联吗？</p></strong><p style='text-align: left;'>删除关联后的凭证将会退回到未关联状态</p>",
        "删除关联",
        {
          dangerouslyUseHTMLString: true,
          type: "error",
          center: true,
          customClass: "deleteRelation",
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          callback: (action) => {
            if (action == "confirm") {
              DeleteCancelEmFolderBindAsync(id).then((res) => {
                this.$message({
                  message: "删除关联成功",
                  type: "success",
                });
                this.pagination.page = 1;
                this.getVoucherFilesList();
              });
            }
          },
        }
      );
    },

    // 选中的文件夹id集合
    chooseVoucherFiles(event, id) {
      if (event) {
        this.checkIdList.push(id);
      } else {
        this.checkIdList.splice(this.checkIdList.indexOf(id), 1);
      }
    },

    // 查看文件夹内凭证图片
    toImageDetail(data, type) {
      this.folderObj = data;
      this.flag[type] = true;
      this.$nextTick(() => this.$refs.imageDetail.getImageVoucher());
    },

    // 获取开票申请编号信息
    getAllOrderList(type) {
      if (type) (this.orderType = type), this.clearSearch();
      this.orderPagination.loading = true;
      let params = {
        pageSize: this.orderPagination.pagesize,
        pageIndex: this.orderPagination.page,
        ...this.orderForm,
      };

      if (this.orderType == "look") {
        params.folderId = this.folderObj.FolderId;
        this.toImageDetail(this.folderObj, "showSmallImageDetail");
      }
      GetInvoiceTaskAsync(params)
        .then((res) => {
          let { TotalCount, DataList } = res.data;
          this.orderPagination.total = TotalCount;
          this.orderPagination.list = DataList.map((item, index) => {
            item.index =
              (this.orderPagination.page - 1) * this.orderPagination.pagesize +
              index +
              1;
            return item;
          });

          this.multipleSelection = "";
        })
        .finally(() => {
          this.orderPagination.loading = false;
        });
    },

    // 单选 - 选中开票申请编号
    handleSelectionChange(currentRow) {
      this.multipleSelection = currentRow;
    },

    // 开票申请编号分页
    handleCurrentOrderChange(e) {
      this.orderPagination.page = e;
      this.getAllOrderList();
    },

    // 清空开票申请编号查询条件
    clearSearch() {
      for (let item in this.orderForm) {
        this.orderForm[item] = "";
      }
    },

    // 关联 / 修改 开票申请编号
    relationOrder() {
      if (this.multipleSelection.length < 1) {
        this.$message({
          message: "请勾选需要关联的开票申请编号",
          type: "info",
        });
        return;
      }

      let params = [];
      params.push(this.multipleSelection);

      let ajaxFn =
        this.orderType == "relation"
          ? PostSetEmFolderBindAsync
          : UpdateEmFolderBindAsync;
      ajaxFn(this.folderObj.FolderId, params).then((res) => {
        this.flag.relationOrder = false;
        this.getVoucherFilesList();
        this.$message({
          message: "关联成功",
          type: "success",
        });
      });
    },

    // 返回上一页， 列表页
    goBack() {
      this.$router.go(-1);
    },

    // 退回凭证
    backFolder(id) {
      console.log("id", id);
      this.$alert(
        "<strong><p style='text-align: left;'>确认要退回凭证吗？</p></strong><p style='text-align: left;'>退回凭证后将会从未关联列表中删除</p>",
        "退回凭证",
        {
          dangerouslyUseHTMLString: true,
          type: "error",
          center: true,
          customClass: "deleteRelation",
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          callback: (action) => {
            if (action == "confirm") {
              DeleteFolderReturnAsync(id).then((res) => {
                console.log("res", res);
                this.$message({
                  message: "退回凭证成功",
                  type: "success",
                });
                this.pagination.page = 1;
                this.getVoucherFilesList();
              });
            }
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.voucherDetail {
  position: relative;

  .ImageDetail {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 99;
  }

  .voucher-files {
    width: 100%;
    box-sizing: border-box;
    margin-top: 2%;
    display: flex;
    flex-wrap: wrap;

    .file-box {
      width: 190px;
      height: 200px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 70px 55px 70px;

      .file-time {
        width: 100%;
        height: 140px;
        background: url("../../../../assets/image/voucherManagement/fileFolder.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        cursor: pointer;

        p {
          position: absolute;
          left: 16px;
          bottom: 16px;
        }

        .edit-name {
          position: absolute;
          right: 16px;
          bottom: 16px;
          font-size: 22px;
          cursor: pointer;
        }
      }

      .checkBtn {
        width: 100%;
        text-align: center;
        margin: 8px 0;
      }
    }
  }

  .goBack {
    margin: 16px 0 0 48%;
  }

  .previewImage-time {
    font-weight: bolder;
  }

  .noData {
    width: 320px;
    height: 270px;
    background: url("../../../../assets/image/voucherManagement/noData.png") no-repeat;
    background-size: 100% 100%;
    margin: 10% auto 0;
  }

  .formItem-title {
    display: flex;
    align-items: center;
    color: #0f5fff;
    margin: 16px 0;

    span {
      display: block;
      width: 1px;
      height: 12px;
      background: #0f5fff;
      border-radius: 12px 12px 12px 12px;
      margin-right: 4px;
    }
  }
}
</style>

<style lang="scss">
.mergePrompt {
  .el-message-box__title {
    font-weight: bolder;
  }

  .el-message-box__content {
    display: flex;
    align-items: center;
    padding: 0px 15px;

    .el-message-box__input {
      width: 80%;
      margin-left: 8px;
    }
  }
}

.deleteRelation {
  .el-message-box__title {
    justify-content: start;
  }

  .el-message-box__content {
    padding: 0px 45px;
  }

  .el-message-box__btns {
    text-align: right;
  }
}

.previewImage {
  // top: -10%;

  .el-dialog__header,
  .el-dialog__body {
    padding: 5px;
  }

  .el-dialog__footer {
    padding: 10px 20px 10px;
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.relationOrder {
  // top: -10%;

  .el-dialog__body {
    padding: 16px;
  }
}

.smallImageDetail {
  &.facilityBox {
    height: auto;
  }

  .image-box {
    width: 160px !important;

    .image {
      height: 100px !important;
    }
  }
}

.voucherDetail {
  .el-dialog {
    top: -10%;
  }
}
</style>
